import React, { Component, Fragment } from 'react'
import { navigate } from 'gatsby'

import { PortfolioItem } from '.'
import SEO from './seo'
import withWindowSize from './withWindowSize'
import '../styles/completePortfolio_main.scss'

const getImage = (images, name) => images.find(image => image.originalName === `smallProject-${name}.png`)

class CompletePortfolio extends Component {
  componentDidUpdate() {
    const { lang, windowWidth } = this.props
    if (windowWidth <= 640) {
      navigate(`${lang.lang}/portfolio`)
    }
  }

  render() {
    const { projectsData, lang, images } = this.props
    return (
      <Fragment>
        <SEO
          lang={lang}
          imageOverride="/seo/portfolio.png"
          titleOverride={`Bliss DDM | ${lang.portfolio}`}
          urlOverride={`https://blissddm.xyz/${lang.lang}/projects`}
        />
        <div className="CompletePortfolio">
          <div className="CompletePortfolio-container">
            <div className="CompletePortfolio-title">{lang.portfolio}</div>
            <div className="CompletePortfolio-worksContainer">
              {projectsData.map(projectData => (
                <div
                  className="CompletePortfolio-projectContainer"
                  key={projectData.name.fullName}
                >
                  <div className="CompletePortfolio-project">
                    <PortfolioItem projectData={projectData} lang={lang} image={getImage(images, projectData.image)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withWindowSize(CompletePortfolio)
