import React, { Fragment } from 'react'
import classNames from 'classnames'
import { navigate, withPrefix } from 'gatsby'
import { Hamburger, LanguageSelector } from '.'
import Fb from '../images/menu/fb.svg'
import FbHover from '../images/menu/fbBlue.svg'
import Behance from '../images/menu/behance.svg'
import BehanceHover from '../images/menu/behanceBlue.svg'

const isPath = (path, location, lang) => [withPrefix(`/${lang.lang}/${path}`), withPrefix(`/${lang.lang}/${path}/`)].indexOf(location.pathname) >= 0

const Menu = ({
  location,
  opened,
  toggleMenu,
  toggleContact,
  willOpen,
  lang,
  langsMenu,
  isHomepage,
  isProjects,
}) => (
  <Fragment>
    <div
      className={classNames('Menu', {
        'Menu--opened': opened,
        'Menu--willOpen': willOpen,
      })}
    >
      <div
        className="Menu-overlay"
        role="button"
        tabIndex="0"
        onClick={() => (willOpen ? false : toggleMenu())}
        onKeyPress={() => (willOpen ? false : toggleMenu())}
      >
        Overlay
      </div>
      <div className="Menu-contentContainer">
        <Hamburger
          toggle={toggleMenu}
          isActive={opened || willOpen}
          className="Menu-hamburger"
        />
        <div className="Menu-content">
          <ul className="Menu-options">
            <li className="Menu-option">
              <button
                type="button"
                onClick={() => toggleMenu(() => navigate(`/${lang.lang}/`))}
                className={classNames('Menu-link', { 'Menu-link--active': isPath('', location, lang) })}
              >
                {lang.menu.home}
              </button>
            </li>
            <li className="Menu-option">
              <button
                type="button"
                onClick={() => toggleMenu(() => navigate(`/${lang.lang}/about/`))}
                className={classNames('Menu-link', { 'Menu-link--active': isPath('about', location, lang) })}
              >
                {lang.menu.weAre}
              </button>
            </li>
            <li className="Menu-option">
              <button
                type="button"
                onClick={() => toggleMenu(() => navigate(`/${lang.lang}/services/`))}
                className={classNames('Menu-link', { 'Menu-link--active': isPath('services', location, lang) })}
              >
                {lang.menu.services}
              </button>
            </li>
            <li className="Menu-option">
              <button
                type="button"
                onClick={() => toggleMenu(() => navigate(`/${lang.lang}/portfolio/`))}
                className={classNames('Menu-link', {
                  'Menu-link--active':
                    isPath('portfolio', location, lang) || isProjects,
                })}
              >
                {lang.portfolio}
              </button>
            </li>
          </ul>
          <ul className="Menu-socials">
            <li className="Menu-social">
              <a
                href="https://www.facebook.com/blissddm/"
                target="_blank"
                rel="noopener"
                className="Menu-socialLink Menu-socialLink"
              >
                <Fb className="Menu-socialIcon" />
                <FbHover className="Menu-socialIcon Menu-socialIcon--hover" />
              </a>
            </li>
            <li className="Menu-social Menu-social--behance">
              <a
                href="https://www.behance.net/BlissDDM"
                target="_blank"
                rel="noopener"
                className="Menu-socialLink Menu-socialLink--behance"
              >
                <Behance className="Menu-socialIcon" />
                <BehanceHover className="Menu-socialIcon Menu-socialIcon--hover" />
              </a>
            </li>
          </ul>
          <span
            tabIndex={0}
            role="button"
            className="Menu-contact"
            onClick={() => {
              toggleMenu()
              toggleContact()
            }}
            onKeyDown={() => {
              toggleMenu()
              toggleContact()
            }}
          >
            {lang.contactLabel}
          </span>
          {/* <span className="Menu-contact Menu-contact--blog">Blog</span> */}
          <LanguageSelector toggleMenu={toggleMenu} langsMenu={langsMenu} />
        </div>
      </div>
    </div>
    {isHomepage && (
      <div className="Menu-homeLanguage">
        <LanguageSelector isHome skipToggleMenu toggleMenu={toggleMenu} langsMenu={langsMenu} />
      </div>
    )}
  </Fragment>
)

export default Menu
