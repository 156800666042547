import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VSensor from 'react-visibility-sensor'

class Animate extends Component {
  constructor(props) {
    super(props)
    this.state = { active: true }
  }

  render() {
    const { active } = this.state
    const { children, className, ...rest } = this.props

    return (
      <VSensor
        {...rest}
        active={active}
        onChange={isVisible => isVisible && this.setState({ active: false })}
      >
        {({ isVisible }) => children(isVisible ? `${className} ${className}--visible` : className)}
      </VSensor>
    )
  }
}

Animate.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
}

export default Animate
