import React, { Component } from 'react'

const withFadeIn = InnerComponent => {
  class WithFadeIn extends Component {
    constructor(props) {
      super(props)

      this.state = { isMounted: false }
    }

    componentDidMount() {
      this.timeout = setTimeout(() => this.setState({ isMounted: true }), 300)
    }

    componentWillUnmount() {
      this.setState({ isMounted: false })
      clearTimeout(this.timeout)
    }

    render() {
      return <InnerComponent {...this.props} {...this.state} />
    }
  }

  return WithFadeIn
}

export default withFadeIn
