/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import axios from 'axios'
import classNames from 'classnames'
import Image from 'gatsby-image'
import { Hamburger } from '.'

class Quote extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        name: '',
        phone: '',
        email: '',
        company: '',
        contactType: '',
        need: 'website',
        description: '',
      },
      errors: {},
      sent: false,
    }

    this.sendForm = this.sendForm.bind(this)
    this.updateRadio = this.updateRadio.bind(this)
    this.updateValue = this.updateValue.bind(this)
  }

  updateValue(event) {
    const { attributes, value } = event.target
    const { form } = this.state
    this.setState({ form: { ...form, [attributes.name.value]: value } })
  }

  updateRadio(event) {
    const { value } = event.target
    const { form } = this.state
    this.setState({ form: { ...form, contactType: value } })
  }

  async sendForm(event) {
    event.preventDefault()

    const requiredValues = ['name', 'phone', 'email']
    const errors = {}
    let hasErrors = false
    const { form } = this.state
    requiredValues.forEach(requiredValue => {
      if (!form[requiredValue] || form[requiredValue] === '') {
        errors[requiredValue] = true
        hasErrors = true
      } else {
        errors[requiredValue] = false
      }
    })

    if (hasErrors) {
      this.setState({ error: true, errors })
      setTimeout(() => this.setState({ errors: {} }), 1000)
      return
    }

    this.setState({ error: false })

    const params = new URLSearchParams()

    Object.keys(form).forEach(key => {
      params.append(key, form[key])
    })

    axios
      .post('/mail.php', params)
      .then(() => this.setState({ sent: true }))
      .catch(() => this.setState({ sent: 'Error' }))
  }

  render() {
    const {
      opened,
      toggleQuote,
      willOpen,
      lang,
      bgImage,
    } = this.props

    const {
      sent,
      error,
      errors,
      form,
    } = this.state
    return (
      <div
        className={classNames('Quote', {
          'Quote--opened': opened,
          'Quote--willOpen': willOpen,
          'Quote--sent': sent,
        })}
      >
        <div className="Quote-backgroundContainer">
          <Image
            className="Quote-background"
            alt="quote background"
            sizes={bgImage}
          />
          <div className="Quote-backgroundContent">
            <div className="Quote-title">
              <h1 className="Quote-titleText">{lang.quoteTitleText}</h1>
              <p className="Quote-paragraph">{lang.quoteParagraph}</p>
            </div>
            <div className="Quote-backgroundDescription">
              {lang.quoteDescription1}
              <br />
              {lang.quoteDescription2}
              <br />
              {lang.quoteDescription3}
            </div>
          </div>
        </div>
        <div className="Quote-contentContainer">
          <Hamburger
            toggle={toggleQuote}
            isActive={opened || willOpen}
            className="Quote-hamburger"
          />
          <div className="Quote-formContainer">
            <div className="Quote-info">{lang.quoteTitle}</div>
            <form
              autoComplete="off"
              className={classNames('Quote-form', { 'Quote-form--error': error })}
              onSubmit={this.sendForm}
            >
              <input type="hidden" name="type" value="quote" />
              <div className="Quote-column Quote-column--left">
                <div className="Quote-inputContainer">
                  <input
                    onChange={this.updateValue}
                    type="text"
                    className={classNames('Quote-input', { 'Quote-input--error': errors.name })}
                    value={form.name}
                    id="nameQuote"
                    name="name"
                    placeholder={lang.formName}
                  />
                  <label htmlFor="nameQuote" className="Quote-inputLabel" aria-label={lang.formName}>
                    {lang.formName}
                  </label>
                </div>
                <div className="Quote-inputContainer">
                  <input
                    onChange={this.updateValue}
                    type="text"
                    className={classNames('Quote-input', { 'Quote-input--error': errors.phone })}
                    value={form.phone}
                    id="phoneQuote"
                    name="phone"
                    placeholder={lang.formTel}
                  />
                  <label htmlFor="phoneQuote" className="Quote-inputLabel" aria-label={lang.formTel}>
                    {lang.formTel}
                  </label>
                </div>
                <div className="Quote-inputContainer">
                  <input
                    onChange={this.updateValue}
                    type="email"
                    className={classNames('Quote-input', { 'Quote-input--error': errors.email })}
                    value={form.email}
                    id="emailQuote"
                    name="email"
                    placeholder={lang.formMail}
                  />
                  <label htmlFor="emailQuote" className="Quote-inputLabel" aria-label={lang.formMail}>
                    {lang.formMail}
                  </label>
                </div>
                <label
                  className="Quote-question Quote-question--last"
                  htmlFor="needQuote"
                >
                  {lang.quoteQuestion1}
                </label>
                <select
                  onBlur={this.updateValue}
                  className="Quote-select"
                  id="needQuote"
                  name="need"
                >
                  <option value="website">{lang.website}</option>
                  <option value="store">{lang.store}</option>
                  <option value="custom">{lang.custom}</option>
                  <option value="app">{lang.apps}</option>
                  <option value="mkt">{lang.marketing}</option>
                </select>
                <label
                  className="Quote-question Quote-question--last"
                  htmlFor="objectiveQuote"
                >
                  {lang.quoteObjetiveQuestion}
                </label>
                <select
                  onBlur={this.updateValue}
                  className="Quote-select"
                  id="objectiveQuote"
                  name="objective"
                >
                  <option value="sales">{lang.formSales}</option>
                  <option value="control">{lang.formControl}</option>
                  <option value="app">{lang.formApp}</option>
                  <option value="social">{lang.formSocial}</option>
                  <option value="website">{lang.formWebsite}</option>
                </select>
              </div>
              <div className="Quote-column">
                <span className="Quote-question">{lang.contactQuestion}</span>
                <div className="Quote-radiosContainer">
                  <div className="Quote-radioContainer">
                    <input
                      onChange={this.updateRadio}
                      type="radio"
                      name="contactType"
                      id="byEmailQuote"
                      value="email"
                    />
                    <label className="Quote-label" htmlFor="byEmailQuote">
                      {lang.contactMail}
                    </label>
                  </div>
                  <div className="Quote-radioContainer">
                    <input
                      onChange={this.updateRadio}
                      type="radio"
                      name="contactType"
                      id="byPhoneQuote"
                      value="phone"
                    />
                    <label className="Quote-label" htmlFor="byPhoneQuote">
                      {lang.contactTel}
                    </label>
                  </div>
                  <div className="Quote-radioContainer">
                    <input
                      onChange={this.updateRadio}
                      type="radio"
                      name="contactType"
                      id="byWhatsQuote"
                      value="whats"
                    />
                    <label className="Quote-label" htmlFor="byWhatsQuote">
                      {lang.whatsapp}
                    </label>
                  </div>
                </div>
                <div className="Quote-inputContainer">
                  <input
                    onChange={this.updateValue}
                    type="text"
                    className="Quote-input"
                    value={form.company}
                    id="companyQuote"
                    name="company"
                    placeholder={lang.formCompany}
                  />
                  <label htmlFor="companyQuote" className="Quote-inputLabel">
                    {lang.formCompany}
                  </label>
                </div>
                <div className="Quote-inputContainer">
                  <input
                    onChange={this.updateValue}
                    type="text"
                    className="Quote-input"
                    value={form.budget}
                    id="budgetQuote"
                    name="budget"
                    placeholder={lang.formEstimate}
                  />
                  <label htmlFor="budgetQuote" className="Quote-inputLabel">
                    ¿Cuentas con un presupuesto?
                  </label>
                </div>
              </div>
              <label
                className="Quote-label Quote-label--hidden"
                htmlFor="descriptionQuote"
              >
                {lang.contactDescription}
              </label>
              <textarea
                onChange={this.updateValue}
                rows="5"
                className="Quote-textarea"
                id="descriptionQuote"
                name="description"
                aria-label={lang.contactDescription}
                placeholder={lang.contactDescription}
              />
              <div className="Quote-submitContainer">
                <button type="submit" className="Quote-submit">
                  {String(lang.quote).toUpperCase()}
                </button>
                <span className="Quote-warning">
                  {lang.formObligatoryFields}
                </span>
              </div>
              <div className="Quote-footer">
                <div className="Quote-footerTitle">{lang.contactLabel}</div>
                <div className="Quote-footerName">Bliss DDM</div>
                <a
                  href="https://goo.gl/maps/N58hRKqefGN2"
                  target="_blank"
                  rel="noopener"
                  className="Quote-footerAddress"
                >
                  Canutillo 209, Pachuca de Soto, Hgo., México
                </a>
                <a
                  href="mailto:hi@blissddm.xyz"
                  className="Quote-footerName Quote-footerName--email"
                >
                  hi@blissddm.xyz
                </a>
                <a
                  href="tel:015568113625"
                  className="Quote-footerName Quote-footerName--phone"
                >
                  01 55 68113625
                </a>
              </div>
            </form>
            <div
              className={classNames('Quote-successContainer', { 'Quote-successContainer--sent': sent })}
            >
              <Hamburger
                toggle={toggleQuote}
                isActive={opened || willOpen}
                className="Quote-hamburger"
              />
              <span className="Quote-infoContent Quote-infoContent--pink">
                {lang.formInfoThanks}
              </span>
              <span className="Quote-infoContent">{lang.formInfoContent}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Quote.propTypes = {
  opened: PropTypes.bool,
  willOpen: PropTypes.bool,
  toggleQuote: PropTypes.func.isRequired,
  lang: PropTypes.shape({}).isRequired,
  bgImage: PropTypes.shape({}).isRequired,
}

Quote.defaultProps = {
  opened: false,
  willOpen: false,
}

export default Quote
