export { default as Main } from './main.svg'
export { default as MainHover } from './mainHover.svg'
export { default as Android } from './android.svg'
export { default as AndroidHover } from './androidHover.svg'
export { default as Fb } from './fb.svg'
export { default as Cloud } from './cloud.svg'
export { default as CloudHover } from './cloudHover.svg'
export { default as FbHover } from './fbHover.svg'
export { default as Google } from './google.svg'
export { default as GoogleHover } from './googleHover.svg'
export { default as Instagram } from './instagram.svg'
export { default as InstagramHover } from './instagramHover.svg'
export { default as Design } from './design.svg'
export { default as DesignHover } from './designHover.svg'
export { default as MobileLeftArrow } from './mobileLeftArrow.svg'
export { default as MobileRightArrow } from './mobileRightArrow.svg'
export { default as MxFlag } from './mxFlag.svg'
export { default as CanadaFlag } from './canadaFlag.svg'
export { default as EuFlag } from './euFlag.svg'
export { default as BlueLeftArrow } from './blueLeftArrow.svg'
export { default as BlueRightArrow } from './blueRightArrow.svg'
