import React from 'react'
import { Hero } from '.'

import '../styles/home.scss'

const HomePage = ({ lang, toggleQuote, images, toggleContact }) => (
  <Hero
    lang={lang}
    toggleQuote={toggleQuote}
    images={images}
    toggleContact={toggleContact}
  />
)

export default HomePage
