import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { Hamburger } from '.'

const Header = ({
  scrolled,
  isHomepage,
  noShowQuote,
  contrast,
  toggleMenu,
  lang,
  opened,
  logo,
  toggleQuote,
  hideOnMobile,
}) => (
  <div
    className={classNames('Header', {
      'Header--contrast': contrast,
      'Header--scrolled': scrolled && !isHomepage,
      'Header--homepage': isHomepage,
      'Header--hideOnMobile': hideOnMobile,
    })}
  >
    <div className="Header-container">
      <Link to={`/${lang.lang}/`} className="Header-left">
        <div className="Header-logoContainer">
          <Image
            sizes={logo}
            alt="Bliss DDM Logo"
            className="Header-logo"
          />
        </div>
      </Link>
      <div className="Header-menu">
        {(isHomepage || !noShowQuote) && (
          <div className="Header-menuLabel">
            <span>
              {lang.quotes} {' '}
              <b
                className="blueHighlight"
                onClick={toggleQuote}
                onKeyPress={toggleQuote}
                tabIndex={0}
                role="button"
              >
                {lang.here} {' '}
              </b>
              {lang.withUS}
            </span>
          </div>
        )}
        <Hamburger
          toggle={toggleMenu}
          isActive={opened}
          className="Header-hamburger"
        />
      </div>
    </div>
  </div>
)

Header.propTypes = {
  scrolled: PropTypes.bool.isRequired,
  isHomepage: PropTypes.bool.isRequired,
  noShowQuote: PropTypes.bool,
  contrast: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
  lang: PropTypes.shape().isRequired,
  logo: PropTypes.shape().isRequired,
  opened: PropTypes.bool.isRequired,
}

Header.defaultProps = { contrast: false }

export default Header
