import React, { Fragment } from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from './seo'
import BlissLabel from './blissLabel'

const settings = {
  dots: true,
  infinite: true,
  autoplaySpeed: 8000,
  autoplay: true,
  accessibility: false,
  speed: 2000,
  arrows: false,
}

const getSizes = images => images.find(image => image.originalName === 'background.png')

const Hero = ({ lang, toggleQuote, images }) => {
  const slides = [
    {
      title: lang.heroSlideTitle1,
      blueTitle: lang.heroSlideBlueTitle1,
      cta: lang.heroSlideCta1,
      link: '/services/web-design',
    },
    {
      title: lang.heroSlideTitle2,
      blueTitle: lang.heroSlideBlueTitle2,
      cta: lang.heroSlideCta2,
      link: '/services/iot',
    },
    {
      title: lang.heroSlideTitle3,
      blueTitle: lang.heroSlideBlueTitle3,
      cta: lang.heroSlideCta3,
      link: '/services/digital-marketing',
    },
    {
      title: lang.heroSlideTitle4,
      blueTitle: lang.heroSlideBlueTitle4,
      cta: lang.heroSlideCta4,
      link: '/services/apps-development',
    },
    {
      title: lang.heroSlideTitle5,
      blueTitle: lang.heroSlideBlueTitle5,
      cta: lang.heroSlideCta5,
      link: 'about',
    },
    {
      title: lang.heroSlideTitle6,
      blueTitle: lang.heroSlideBlueTitle6,
      cta: lang.heroSlideCta6,
      link: 'portfolio',
    },
  ]
  return (
    <Fragment>
      <SEO lang={lang} />
      <div className="Hero">
        <Img
          className="Hero-background"
          sizes={getSizes(images)}
          style={{ position: 'absolute' }}
        />
        <Slider {...settings}>
          {slides.map((slide, idx) => (
            <div
              key={slide.title}
              className={`Hero-slide Hero-slide--${idx + 1}`}
            >
              <div className="Hero-container">
                <div className="Hero-blissLabel"><BlissLabel label={lang.companyLabel} /></div>
                <div className="Hero-title">
                  {slide.title} {' '}
                  <span className="Hero-title--blueTitle">{slide.blueTitle}</span>
                </div>
                {slide.link ? (
                  <Link
                    as="button"
                    aria-label={slide.cta}
                    to={`/${lang.lang}/${slide.link}`}
                    className="Hero-button"
                  >
                    {slide.cta}
                  </Link>
                ) : (
                  <button tabIndex={0} onKeyPress={toggleQuote} className="Hero-button" type="button" onClick={toggleQuote}>
                    {slide.cta}
                  </button>
                )}
                {!slide.link && (
                  <Link to={`/${lang.lang}/services`} className="Hero-info">
                    {lang.heroInfo}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Fragment>
  )
}

export default Hero
