import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Animate from './animate'
import '../styles/portfolioItem_main.scss'

const PortfolioItem = ({ projectData, image, lang }) => (
  <div className="PortfolioItem">
    <div className="PortfolioItem-container">
      <Animate className="PortfolioItem-image">
        {classNames => (
          <Img
            className={classNames}
            alt={projectData.image}
            sizes={image}
          />
        )}
      </Animate>
      <Animate className="PortfolioItem-content">
        {classNames => (
          <div className={classNames}>
            <div className="PortfolioItem-info">
              <div className="PortfolioItem-name">
                {projectData.name.white}
                <span className="PortfolioItem-name PortfolioItem-name--blue">
                  {projectData.name.blue}
                </span>
              </div>
              <div className="PortfolioItem-description">
                {projectData.caption}
              </div>
            </div>
            <div className="PortfolioItem-btnContainer">
              <Link to={projectData.path}>
                <button
                  type="button"
                  className="PortfolioItem-btn"
                >
                  {lang.lookMore}
                </button>
              </Link>
            </div>
          </div>
        )}
      </Animate>
    </div>
  </div>
)

export default PortfolioItem
