import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Hamburger } from '.'

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        name: '',
        phone: '',
        email: '',
        company: '',
        contactType: '',
        need: 'website',
        description: '',
      },
      errors: {},
      sent: false,
    }

    this.sendForm = this.sendForm.bind(this)
    this.updateRadio = this.updateRadio.bind(this)
    this.updateValue = this.updateValue.bind(this)
  }

  updateValue(event) {
    const { attributes, value } = event.target

    this.setState(prevState => ({ form: { ...prevState.form, [attributes.id.value]: value } }))
  }

  updateRadio(event) {
    const { value } = event.target

    this.setState(prevState => ({ form: { ...prevState.form, contactType: value } }))
  }

  async sendForm(event) {
    event.preventDefault()
    const { form } = this.state
    const requiredValues = ['name', 'phone', 'email']
    const errors = {}
    let hasErrors = false
    requiredValues.forEach(requiredValue => {
      if (
        !form[requiredValue]
        || form[requiredValue] === ''
      ) {
        errors[requiredValue] = true
        hasErrors = true
      } else {
        errors[requiredValue] = false
      }
    })

    if (hasErrors) {
      this.setState({ error: true, errors })
      setTimeout(() => this.setState({ errors: {} }), 1000)
      return
    }
    this.setState({ error: false })


    const params = new URLSearchParams()

    for (const key in this.state.form) {
      params.append(key, this.state.form[key])
    }

    axios
      .post('/mail.php', params)
      .then(() => this.setState({ sent: true }))
      .catch(() => this.setState({ sent: 'Error' }))
  }

  render() {
    const { opened, toggleContact, willOpen, lang } = this.props
    const { sent, error, errors, form } = this.state
    return (
      <div
        className={classNames('Contact', {
          'Contact--opened': opened,
          'Contact--willOpen': willOpen,
          'Contact--sent': sent,
        })}
      >
        <div
          className="Contact-overlay"
          role="button"
          tabIndex="0"
          onClick={() => (willOpen ? false : toggleContact())}
          onKeyDown={() => (willOpen ? false : toggleContact())}
        >
          Overlay
        </div>
        <div className="Contact-contentContainer">
          <Hamburger
            toggle={toggleContact}
            isActive={opened || willOpen}
            className="Menu-hamburger"
          />
          <div className="Contact-formContainer">
            <div className="Contact-info">{lang.contactInfo}</div>
            <form
              autoComplete="off"
              className={classNames('Contact-form', { 'Contact-form--error': error })}
              onSubmit={this.sendForm}
            >
              <input type="hidden" name="type" value="contact" />
              <div className="Contact-inputContainer">
                <input
                  onChange={this.updateValue}
                  type="text"
                  className={classNames('Contact-input', { 'Contact-input--error': errors.name })}
                  value={form.name}
                  id="name"
                  name="name"
                  placeholder={lang.formName}
                />
                <label htmlFor="name" className="Contact-inputLabel" aria-label={lang.formName}>
                  {lang.formName}
                </label>
              </div>
              <div className="Contact-inputContainer">
                <input
                  onChange={this.updateValue}
                  type="text"
                  className={classNames('Contact-input', { 'Contact-input--error': errors.phone })}
                  value={form.phone}
                  id="phone"
                  name="phone"
                  placeholder={lang.formTel}
                />
                <label htmlFor="phone" className="Contact-inputLabel" aria-label={lang.formTel}>
                  {lang.formTel}
                </label>
              </div>
              <div className="Contact-inputContainer">
                <input
                  onChange={this.updateValue}
                  type="email"
                  className={classNames('Contact-input', { 'Contact-input--error': errors.email })}
                  value={form.email}
                  id="email"
                  name="email"
                  placeholder={lang.formMail}
                />
                <label htmlFor="email" className="Contact-inputLabel" aria-label={lang.formMail}>
                  {lang.formMail}
                </label>
              </div>
              <div className="Contact-inputContainer">
                <input
                  onChange={this.updateValue}
                  type="text"
                  className={classNames('Contact-input', { 'Contact-input--error': errors.company })}
                  value={form.company}
                  id="company"
                  name="company"
                  placeholder={lang.formCompany}
                />
                <label htmlFor="company" className="Contact-inputLabel" aria-label={lang.formCompany}>
                  {lang.formCompany}
                </label>
              </div>
              <span className="Contact-question">{lang.contactQuestion}</span>
              <div className="Contact-radioContainer">
                <input
                  onChange={this.updateRadio}
                  type="radio"
                  name="contactType"
                  id="byEmail"
                  value="email"
                />
                <label className="Contact-label" htmlFor="byEmail">
                  {lang.contactMail}
                </label>
              </div>
              <div className="Contact-radioContainer">
                <input
                  onChange={this.updateRadio}
                  type="radio"
                  name="contactType"
                  id="byPhone"
                  value="phone"
                />
                <label className="Contact-label" htmlFor="byPhone">
                  {lang.contactTel}
                </label>
              </div>
              <div className="Contact-radioContainer">
                <input
                  onChange={this.updateRadio}
                  type="radio"
                  name="contactType"
                  id="byWhats"
                  value="whats"
                />
                <label className="Contact-label" htmlFor="byWhats">
                  {lang.whatsapp}
                </label>
              </div>
              <label
                className="Contact-question Contact-question--last"
                htmlFor="need"
              >
                {lang.contactQuestion2}
              </label>
              <select
                onChange={this.updateValue}
                onBlur={this.updateValue}
                className="Contact-select"
                id="need"
                name="need"
              >
                <option value="website">{lang.website}</option>
                <option value="store">{lang.store}</option>
                <option value="custom">{lang.custom}</option>
                <option value="app">{lang.apps}</option>
                <option value="mkt">{lang.marketing}</option>
              </select>
              <label
                className="Contact-label Contact-label--hidden"
                htmlFor="description"
              >
                {lang.description}
              </label>
              <textarea
                onChange={this.updateValue}
                rows="5"
                className="Contact-textarea"
                id="description"
                name="description"
                placeholder={lang.contactDescription}
              />
              <div className="Contact-submitContainer">
                <button type="submit" className="Contact-submit">
                  {String(lang.sent).toUpperCase()}
                </button>
                <span className="Contact-warning">
                  {lang.formObligatoryFields}
                </span>
              </div>
              <div className="Contact-footer">
                <div className="Contact-footerTitle">{lang.contactLabel}</div>
                <div className="Contact-footerName">Bliss DDM</div>
                <a
                  href="https://goo.gl/maps/N58hRKqefGN2"
                  target="_blank"
                  rel="noopener"
                  className="Contact-footerAddress"
                >
                  Canutillo 209, Pachuca de Soto, Hgo., México
                </a>
                <a
                  href="mailto:hi@blissddm.xyz"
                  className="Contact-footerName Contact-footerName--email"
                >
                  hi@blissddm.xyz
                </a>
                <a
                  href="tel:+525535015182"
                  className="Contact-footerName Contact-footerName--phone"
                >
                  +52 55 3501 5182
                </a>
              </div>
            </form>
            <div
              className={classNames('Contact-successContainer', { 'Contact-successContainer--sent': sent })}
            >
              <span className="Contact-infoContent Contact-infoContent--pink">
                {lang.formDataThanks}
              </span>
              <span className="Contact-infoContent">
                {lang.formInfoContent}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
