import React, { Component } from 'react'
import throttle from 'lodash/throttle'

const withWindowSize = InnerComponent => {
  class WithScroll extends Component {
    constructor(props) {
      super(props)

      this.handleResize = throttle(this.handleResize.bind(this), 100)
    }

    componentDidMount() {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
      this.setState({ windowWidth: window.innerWidth })
    }

    render() {
      return <InnerComponent {...this.props} {...this.state} />
    }
  }

  return WithScroll
}

export default withWindowSize
