import React, { Component } from 'react'
import throttle from 'lodash/throttle'

const withScroll = InnerComponent => {
  class WithScroll extends Component {
    constructor(props) {
      super(props)

      this.state = { scrolled: false }
      this.handleScroll = throttle(this.handleScroll.bind(this), 100)
    }

    componentDidMount() {
      this.handleScroll()
      window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll() {
      const { scrolled } = this.state
      if (window.scrollY > 0 && !scrolled) {
        this.setState({ scrolled: true })
      } else if (window.scrollY <= 5 && scrolled) {
        this.setState({ scrolled: false })
      }
    }

    render() {
      return <InnerComponent {...this.props} {...this.state} />
    }
  }

  return WithScroll
}

export default withScroll
