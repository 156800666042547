import React from 'react'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import Arrow from '../images/blue_arrow.svg'

const getClassNames = (modifier, label) => classNames('ArrowButton', modifier ? `ArrowButton--${modifier}` : '', { 'ArrowButton--noText': !label })
const Button = ({ label, children, modifier }) => <button className={getClassNames(modifier, label)} type="button">{children}</button>
const Link = ({ label, to, children, modifier }) => (
  <GatsbyLink to={to} className={getClassNames(modifier, label)}>
    {children}
  </GatsbyLink>
)

const ArrowButton = ({ label, to, modifier }) => !to ? (
  <Button modifier={modifier} label={label}>
    <div className="ArrowButton-arrow"><Arrow /></div>
    {label && <div className="ArrowButton-barText">{label}</div>}
  </Button>
) : (
  <Link label={label} to={to} modifier={modifier}>
    <div className="ArrowButton-arrow"><Arrow /></div>
    {label && <div className="ArrowButton-barText">{label}</div>}
  </Link>
)

export default ArrowButton
