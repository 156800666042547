import React from 'react'
import classNames from 'classnames'
import FbBlue from '../images/menu/fbBlue.svg'
import BehanceBlue from '../images/menu/behanceBlue.svg'
import ContactIcon from '../images/home/contact.svg'

const ContactTile = ({ isOpen, onClick, label }) => (
  <div className="ContactTile">
    <div className={classNames('ContactTile-outerContainer', { 'ContactTile-outerContainer--displayed': isOpen })}>
      <button type="button" className="ContactTile-toggle" onClick={onClick}>
        <span className="ContactTile-label">
          {label}
        </span>
        <ContactIcon />
      </button>
      <div className="ContactTile-info">
        <div className="ContactTile-container">
          <a className="ContactTile-number" href="tel:+525535015182">+52 55 3501 5182</a>
          <a
            href="https://www.behance.net/BlissDDM"
            target="_blank"
            rel="noopener"
          >
            <BehanceBlue />
          </a>

          <a
            href="http://facebook.com/blissddm"
            target="_blank"
            rel="noopener"
          >
            <FbBlue />
          </a>
          <a className="ContactTile-mail" href="mailto:hi@blissddm.xyz">hi@blissddm.xyz</a>
        </div>
      </div>
    </div>
  </div>
)

export default ContactTile
