import React, { Component } from 'react'
import noop from 'lodash/noop'

const withMenu = (
  InnerComponent,
  opened = 'openedMenu',
  toggle = 'toggleMenu',
  open = 'openMenu',
  close = 'closeMenu',
) => {
  class WithMenu extends Component {
    constructor(props) {
      super(props)

      this.state = { [opened]: false }

      const keyListener = e => {
        if (e.which === 27 || e.which === '27') {
          this[close]()
          document.removeEventListener('keyup', keyListener)
        }
      }

      this[open] = () => {
        this.setState(prevState => ({ [opened]: !prevState[opened] }))
      }

      this[close] = cb => {
        this.setState(prevState => ({ [opened]: !prevState[opened] }))
        setTimeout(cb, 300)
        document.removeEventListener('keyup', keyListener)
      }

      this[toggle] = (cb = noop) => {
        // eslint-disable-next-line react/destructuring-assignment
        if (!this.state[opened]) {
          this[open]()

          document.addEventListener('keyup', keyListener)
        } else {
          this[close](cb)
        }
      }
    }

    render() {
      // debugger
      return <InnerComponent {...this} {...this.props} {...this.state} />
    }
  }

  return WithMenu
}

export default withMenu
