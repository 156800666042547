import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Animate from './animate'

import SEO from './seo'
import ArrowButton from './arrowButton'
import '../styles/portfolio_main.scss'

const getImage = (images, name, isPortfolio = false) => images.find(image => image.originalName === (isPortfolio ? `portfolio-${name}.png` : `portfoliomobile-${name}.png`))

const Portfolio = ({ lang, projects, secondaryProjects, images }) => (
  <Fragment>
    <SEO
      lang={lang}
      descriptionOverride={lang.portfolioSeo}
      titleOverride={`Bliss DDM | ${lang.portfolio}`}
      imageOverride="/seo/portfolio.png"
      urlOverride={`https://blissddm.xyz/${lang.lang}/portfolio`}
    />
    <div className="Portfolio">
      <div className="Portfolio-container">
        <div className="Portfolio-title">{lang.portfolio}</div>
        {projects.map(project => (
          <div className="Portfolio-workContainer" key={project.name.fullName}>
            <Img
              className="Portfolio-image"
              sizes={getImage(images, project.image, true)}
              style={{ position: 'absolute' }}
            />
            <div className="Portfolio-overlay" />
            <div className="Portfolio-workInfo">
              <div className="Portfolio-infoContainer">
                <Animate className="Portfolio-workName">
                  {classNames => (
                    <h3 className={classNames}>{project.name.fullName}</h3>
                  )}
                </Animate>
                <Animate className="Portfolio-workDescription">
                  {classNames => (
                    <div className={classNames}>
                      {project.caption}
                    </div>
                  )}
                </Animate>
                <div className="Portfolio-projectBtnContainer">
                  <Link to={project.path}>
                    <Animate className="Portfolio-projectBtn">
                      {classNames => (
                        <button
                          type="button"
                          className={classNames}
                        >
                          {lang.projectBtn}
                        </button>
                      )}
                    </Animate>
                  </Link>
                </div>
              </div>
              <Link to={project.path} className="Portfolio-infoContainer Portfolio-infoContainer--mobile">
                <Animate className="Portfolio-workName">
                  {classNames => (
                    <h3 className={classNames}>{project.name.fullName}</h3>
                  )}
                </Animate>
                <Animate className="Portfolio-viewProject">
                  {classNames => (
                    <div className={classNames}>
                      {lang.projectBtn}
                    </div>
                  )}
                </Animate>
              </Link>
            </div>
          </div>
        ))}
        {projects.concat(secondaryProjects).map(project => (
          <Link to={project.path} className="Portfolio-workContainer Portfolio-workContainer--mobile" key={project.name.fullName}>
            <Img
              className="Portfolio-image"
              sizes={getImage(images, project.image)}
              style={{ position: 'absolute' }}
            />
            <div className="Portfolio-overlay" />
            <div className="Portfolio-workInfo">
              <div className="Portfolio-infoContainer Portfolio-infoContainer--mobile">
                <Animate className="Portfolio-workName">
                  {classNames => (
                    <h3 className={classNames}>{project.name.fullName}</h3>
                  )}
                </Animate>
                <Animate className="Portfolio-viewProject">
                  {classNames => (
                    <div className={classNames}>
                      {lang.projectBtn}
                    </div>
                  )}
                </Animate>
              </div>
            </div>
          </Link>
        ))}
        <Link to={`/${lang.lang}/projects`}>
          <ArrowButton modifier="portfolio" label={lang.barText} />
        </Link>
      </div>
    </div>
  </Fragment>
)

export default Portfolio
