import React, { Fragment } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'

import Animate from './animate'
import ArrowButton from './arrowButton'
import BrochureTile from './brochureTile'
import Seo from './seo'
import Arrow from '../images/arrow.svg'
import BlissLabel from './blissLabel'
import '../styles/services_main.scss'

import appsDevelopment from '../images/services/small/appsDevelopment.svg'
import communityManagement from '../images/services/small/communityManagement.svg'
import customDevelopment from '../images/services/small/customDevelopment.svg'
import digitalMarketing from '../images/services/small/digitalMarketing.svg'
import eCommerce from '../images/services/small/eCommerce.svg'
import visualIdentityDesign from '../images/services/small/visualIdentityDesign.svg'
import webDesign from '../images/services/small/webDesign.svg'
import iot from '../images/services/small/iot.svg'

const svgFiles = {
  appsDevelopment,
  communityManagement,
  customDevelopment,
  digitalMarketing,
  eCommerce,
  visualIdentityDesign,
  webDesign,
  iot,
}

const getSliderImages = images => images.filter(image => image.originalName.indexOf('services-') >= 0)
const getLaptopImage = images => images.filter(image => image.originalName.indexOf('laptop') >= 0)

const settings = {
  dots: true,
  infinite: true,
  autoplaySpeed: 5000,
  autoplay: true,
  speed: 2000,
  arrows: false,
}

const Services = ({ lang, images, servicesData, toggleQuote }) => {
  const sliderImages = getSliderImages(images)
  const laptopImage = getLaptopImage(images)[0]

  return (
    <Fragment>
      <Seo
        lang={lang}
        titleOverride={`Bliss DDM | ${lang.menu.services}`}
        descriptionOverride={lang.servicesSeo}
        imageOverride="/seo/services.png"
        urlOverride={`https://blissddm.xyz/${lang.lang}/services`}
      />
      <div className="Services">
        <BrochureTile label={lang.downloadBrochure} lang={lang.lang} />
        <div className="Services-hero">
          <div className="Services-info">
            <div className="Services-titleContainer">
              <BlissLabel label={lang.companyLabel} />
              <div className="Services-title">
                {lang.our}
                <br />
                <span dangerouslySetInnerHTML={{ __html: lang.services }} />
              </div>
            </div>
            <div className="Services-mainDescription">
              {lang.servicesDescription}
              <br />
              <br />
              {lang.servicesDescription2}
              <br />
              <AnchorLink className="Services-mobileArrow" href="#list">
                <Arrow />
              </AnchorLink>
            </div>

            <AnchorLink className="Services-arrow" href="#list">
              <ArrowButton />
            </AnchorLink>
          </div>
          <div className="Services-laptop">
            <Slider {...settings} className="Services-slider">
              {sliderImages.map((slide, idx) => (
                <div
                  key={slide.src}
                  className={`Services-slide Services-slide--${idx + 1}`}
                >
                  <Img
                    className="Services-sliderImage"
                    alt="laptop"
                    sizes={slide}
                  />
                </div>
              ))}
            </Slider>
            <Img
              className="Services-laptopImage"
              alt="laptop"
              sizes={laptopImage}
            />
          </div>
        </div>
        <div className="Services-list" id="list">
          {servicesData.map(({ context: { serviceData: service }, path }, idx) => {
            const SVGItem = svgFiles[service.image]
            return (
              <div
                key={service.name}
                className={classNames('Services-listItem', {
                  'Services-listItem--reverse': [2, 3, 6, 7].indexOf(idx) >= 0,
                  'Services-listItem--even': idx % 2 !== 0,
                  'Services-listItem--odd': idx % 2 === 0,
                })}
              >
                <Animate className="Services-itemInfo">
                  {animationClassNames => (
                    <div className={animationClassNames}>
                      <h1 className="Services-itemTitle">{service.name}</h1>
                      <div className="Services-itemDescription" dangerouslySetInnerHTML={{__html: service.description}} />
                      <Link className="Services-itemLink" to={path}>{lang.viewMore}</Link>
                    </div>
                  )}
                </Animate>
                <Animate className="Services-itemImage">
                  {animationClassNames => (
                    <SVGItem className={animationClassNames} alt={service.name} />
                  )}
                </Animate>
              </div>
            )
          })}
        </div>
        <div
          className="Services-mobileTitle"
          dangerouslySetInnerHTML={{ __html: lang.contactLabelHTML }}
        />
        <div
          className="Services-quote"
          onClick={toggleQuote}
          onKeyPress={toggleQuote}
          tabIndex={0}
          role="button"
        >
          {lang.quotes} {' '}
          <b className="blueHighlight">
            {lang.here} {' '}
          </b>
          {lang.withUS}
        </div>
      </div>
    </Fragment>
  )
}

export default Services
