import React from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'

const LanguageSelector = ({ langsMenu, isHome, toggleMenu, skipToggleMenu }) => (
  <div
    className={classNames('LanguageSelector-languagesContainer', { 'LanguageSelector-languagesContainer--home': isHome })}
  >
    <ul className="LanguageSelector-languages">
      {langsMenu.map((lang, idx) => (
        <li key={lang.langKey} className="LanguageSelector-language">
          <button
            type="button"
            className={classNames('LanguageSelector-languageLink', { 'LanguageSelector-languageLink--selected': lang.selected })}
            onClick={() => skipToggleMenu ? navigate(lang.link) : toggleMenu(() => navigate(lang.link))}
            to={lang.link}
          >
            {lang.langKey}
          </button>
          {langsMenu.length - 1 !== idx && (
          <span className="LanguageSelector-diagonal">/</span>
          )}
        </li>
      ))}
    </ul>
  </div>
)

export default LanguageSelector
