import React from 'react'
import classNames from 'classnames'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { getLangs, getUrlForLang, getCurrentLangKey } from 'ptz-i18n'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/base.scss'
import ContactTile from './contactTile'

import {
  Header,
  Menu,
  Contact,
  Quote,
  withMenu,
  withScroll,
  withFadeIn,
} from '.'

const Layout = ({
  lang,
  children,
  location,
  isMounted,
  isHomepage,
  isServices,
  noShowQuote,
  contrast,
  isProjects,
  openedMenu,
  willOpenMenu,
  toggleMenu,
  openedContact,
  willOpenContact,
  toggleContact,
  openedSmallContact,
  willOpenSmallContact,
  toggleSmallContact,
  openedQuote,
  willOpenQuote,
  toggleQuote,
  scrolled,
  component,
  images,
  logo,
  hideHeaderOnMobile,
  noShowContactTile,
  ...rest
}) => {
  const Component = component
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={({ site }) => {
        const url = location.pathname
        const { langs, defaultLangKey } = site.siteMetadata.languages
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeUrl = `/${langKey}/`
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeUrl, url))
        return (
          <>
            <Helmet
              title={site.siteMetadata.title}
              meta={[
                { name: 'description', content: lang.description },
                {
                  name: 'google-site-verification',
                  content: 'i0phccauPSDbtTEIgRGI2OTnnAOrX59rSasrGeO_Rso',
                },
                { name: 'keywords', content: lang.keywords },
              ]}
            >
              <html lang={lang.lang} />
            </Helmet>
            <Header
              hideOnMobile={hideHeaderOnMobile}
              isHomepage={isHomepage}
              noShowQuote={noShowQuote}
              contrast={contrast}
              logo={logo}
              toggleMenu={toggleMenu}
              toggleContact={toggleContact}
              opened={openedMenu}
              willOpen={willOpenMenu}
              contactLabel={lang.contactLabel}
              scrolled={scrolled}
              lang={lang}
              toggleQuote={toggleQuote}
            />
            <Menu
              location={location}
              lang={lang}
              opened={openedMenu}
              willOpen={willOpenMenu}
              toggleMenu={toggleMenu}
              toggleContact={toggleContact}
              langsMenu={langsMenu}
              isHomepage={isHomepage}
              isProjects={isProjects}
            />
            <Contact
              opened={openedContact}
              willOpen={willOpenContact}
              toggleContact={toggleContact}
              lang={lang}
            />
            <Quote
              opened={openedQuote}
              willOpen={willOpenQuote}
              toggleQuote={toggleQuote}
              lang={lang}
              bgImage={images.find(image => image.originalName === 'bg.png')}
            />
            <div
              className={classNames('Layout', {
                'Layout--mounted': isMounted,
                'Layout--portfolio': isProjects,
              })}
            >
              {children}
              {Component && (
                <Component
                  toggleQuote={toggleQuote}
                  lang={lang}
                  images={images}
                  toggleContact={toggleContact}
                  {...rest}
                />
              )}
              {!noShowContactTile && (
                <ContactTile
                  onClick={toggleSmallContact}
                  isOpen={openedSmallContact}
                  label={lang.contactLabel}
                />
              )}
            </div>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  lang: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  logo: PropTypes.shape({}).isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
  isMounted: PropTypes.bool,
  isHomepage: PropTypes.bool,
  isServices: PropTypes.bool,
  contrast: PropTypes.bool,
  isProjects: PropTypes.bool,
  openedMenu: PropTypes.bool,
  openedSmallContact: PropTypes.bool,
  openedContact: PropTypes.bool,
  openedQuote: PropTypes.bool,
  scrolled: PropTypes.bool,
  component: PropTypes.func,
  toggleSmallContact: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  toggleContact: PropTypes.func.isRequired,
  toggleQuote: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noShowQuote: PropTypes.bool,
  noShowContactTile: PropTypes.bool
}

Layout.defaultProps = {
  isMounted: false,
  isHomepage: false,
  isServices: false,
  contrast: false,
  isProjects: false,
  openedMenu: false,
  openedSmallContact: false,
  openedContact: false,
  openedQuote: false,
  scrolled: false,
  component: null,
  children: null,
}

export default withFadeIn(
  withScroll(
    withMenu(
      withMenu(
        withMenu(
          withMenu(
            Layout,
            'openedSmallContact',
            'toggleSmallContact',
            'openSmallContact',
            'closeSmallContact',
          ),
          'openedContact',
          'toggleContact',
          'openContact',
          'closeContact',
        ),
        'openedQuote',
        'toggleQuote',
        'openQuote',
        'closeQuote',
      ),
    ),
  ),
)
