import React from 'react'
import DownloadIcon from '../images/services/download.svg'

const BrochureTile = ({ label, lang }) => (
  <div className="BrochureTile">
    <div className="BrochureTile-outerContainer">
      <a className="BrochureTile-toggle" href={`/Bliss DDM Brochure ${lang}.pdf`} target="_blank">
        <DownloadIcon />
        <span className="BrochureTile-label">
          {label}
        </span>
      </a>
    </div>
  </div>
)

export default BrochureTile
