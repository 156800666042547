import React, { Component } from 'react'
import classNames from 'classnames'
import throttle from 'lodash/throttle'
import Scroll from '../images/about/scroll.svg'
import ProcessCircle from '../images/about/circle.svg'

class TrackedProcess extends Component {
  constructor(props) {
    super(props)

    this.state = { fixed: false, finished: false }
    this.onScroll = throttle(this.onScroll.bind(this), 50)
    this.container = React.createRef()
    this.circle = React.createRef()
  }

  componentDidMount() {
    this.onScroll()
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    if (window.innerWidth >= 640) {
      return
    }

    const rects = this.container.current.getClientRects()
    const { setPage } = this.props

    if (rects.length) {
      const { top, height } = rects[0]
      const { innerHeight } = window

      if (top < 0) {
        this.setState({ fixed: true })
      } else {
        this.setState({ fixed: false })
      }

      if (height + top > innerHeight) {
        this.setState({ finished: false })
      } else {
        this.setState({ finished: true })
      }

      const page = Math.floor(Math.abs(top / (height * 0.16)))
      setPage(page === 0 ? null : page - 1)
    }
  }

  render() {
    const { fixed, finished } = this.state
    const { children } = this.props
    return (
      <div className="About-mobileProcessCircleContainer" ref={this.container}>
        <Scroll
          className={classNames(
            'About-hand',
            {
              'About-hand--fixed': fixed,
              'About-hand--finished': finished,
            },
          )}
        />

        <div
          ref={this.circle}
          className={classNames(
            'About-processCircle About-processCircle--mobile',
            {
              'About-processCircle--fixed': fixed,
              'About-processCircle--finished': finished,
            },
          )}
        >
          <ProcessCircle className="About-processCircleIcon" />
          {children}
        </div>
      </div>
    )
  }
}

export default TrackedProcess
