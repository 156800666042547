import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Hamburger = ({ toggle, isActive, className }) => (
  <button
    className={classNames(className, 'hamburger hamburger--slider', { 'is-active': isActive })}
    type="button"
    aria-label="menu"
    onClick={toggle}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </button>
)

Hamburger.propTypes = {
  toggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
}

Hamburger.defaultProps = { className: '', isActive: false }

export default Hamburger
