export const getConfig = ({ lang, image: langImage, description }) => {
  let image = null
  if (langImage) {
    if (langImage.indexOf('/') < 0) {
      // eslint-disable-next-line
      image = require(`../images/${langImage}`)
    } else {
      image = langImage
    }
  }

  return {
    url: `https://www.blissddm.xyz/${lang}`,
    twitter: 'https://twitter.com/blissddm',
    title: 'Bliss DDM',
    name: 'Bliss DDM',
    image,
    description,
  }
}

export default getConfig
