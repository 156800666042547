import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { getConfig } from '../data/config'

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  config,
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: config.title,
    },
  ]

  return isBlogPost
    ? [
      ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': url,
              name: title,
              image,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: config.title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
        datePublished,
      },
    ]
    : schemaOrgJSONLD
}

const getImage = ({
  basePath,
  config,
  postImage,
  imageOverride,
  isService,
  // eslint-disable-next-line no-nested-ternary
}) => imageOverride ? (imageOverride.indexOf('/seo/') === 0 ? `${basePath}${imageOverride}` : require(`../images/${imageOverride}`))
  : postImage ? require(`../images/${isService ? 'services' : 'portfolio'}/seo/${postImage}.${isService ? 'png' : 'jpg'}`)
    : `${basePath}${config.image}`

const SEO = ({
  postData,
  postImage,
  isBlogPost,
  isService,
  descriptionOverride,
  titleOverride,
  imageOverride,
  urlOverride,
  lang,
}) => {
  const config = getConfig(lang)
  const title = titleOverride || get(postData, 'title', config.title)
  const description = descriptionOverride || get(postData, 'description', config.description)
  const basePath = config.url.substr(0, config.url.length - 3)
  const image = getImage({
    config,
    basePath,
    postImage,
    imageOverride,
    isService,
  })
  const slug = get(postData, 'slug')
  let url = urlOverride
    || (get(postData, 'path') ? `${basePath}${postData.path}` : null)
    || (slug ? `${config.url}${slug}` : null)
    || config.url

  url = url.replace('/en/en', '/en').replace('/es/es', '/es').replace('www.', '')

  const datePublished = isBlogPost ? postData.date : false

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    title,
    image,
    description,
    datePublished,
    config,
  })

  return (
    <Helmet>
      {/* General tags */}
      {titleOverride && <title>{title}</title>}
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {isBlogPost || isService ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={isBlogPost ? 640 : 300} />
      <meta property="og:image:height" content={isBlogPost ? 480 : 300} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  isService: PropTypes.bool,
  postData: PropTypes.shape({
    frontmatter: PropTypes.any,
    excerpt: PropTypes.any,
  }),
  postImage: PropTypes.string,
  descriptionOverride: PropTypes.string,
  titleOverride: PropTypes.string,
  imageOverride: PropTypes.string,
  urlOverride: PropTypes.string,
  lang: PropTypes.shape({}),
}

SEO.defaultProps = {
  isBlogPost: false,
  isService: false,
  postImage: null,
  postData: null,
  descriptionOverride: null,
  titleOverride: null,
  imageOverride: null,
  urlOverride: null,
  lang: null,
}

export default SEO
