import React, { Component, Fragment } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import '../styles/about_main.scss'
import { MobileProcessCircle, BlissLabel } from '.'
import SEO from './seo'
import Animate from './animate'
import Arrow from '../images/arrow.svg'
import ProcessCircle from '../images/about/circle.svg'

const getImage = (images, name) => images.find(image => image.originalName === `person-${name}.png`)

class About extends Component {
  constructor(props) {
    super(props)
    this.state = { highlightedProcess: null }
    this.enable = this.enable.bind(this)
    this.circleContainer = React.createRef()
  }

  enable(idx) {
    this.setState({ highlightedProcess: idx })
  }

  render() {
    const scrollMobileCircle = currentElement => {
      const containerHeight = this.circleContainer.current.container.current
        .clientHeight
      const top = window.scrollY + this.circleContainer.current.container.current.getBoundingClientRect().y
      const elementHeight = containerHeight / 6
      const scroll = elementHeight * currentElement + top
      window.scrollTo(0, scroll)
    }

    const { enable } = this
    const { highlightedProcess } = this.state
    const { lang, images } = this.props

    const values = lang.valuesTitles.map((title, idx) => ({
      title,
      description: lang.valuesDescriptions[idx],
    }))

    const processDescriptions = lang.processDescriptions.map((description, idx) => ({
      title: lang.processLabels[idx],
      shortDescription: idx === 0 ? lang.processShortDescription : null,
      description,
    }))

    const teamImages = {
      Dunia: {
        name: 'Dunia Munguía',
        title: lang.executiveManager,
      },
      Daniel: {
        name: 'Daniel Rocha',
        title: lang.technologyManager,
      },
      Amauri: {
        name: 'Amauri Sánchez',
        title: lang.businessAnalyst,
      },
      Claudia: {
        name: 'Claudia Arenas',
        title: lang.businessAnalyst,
      },
      David: {
        name: 'David Fernández',
        title: lang.development,
      },
      Ixchel: {
        name: 'Ixchel Gómez',
        title: lang.design,
      },
    }

    const team = Object.keys(teamImages).map(image => ({
      name: teamImages[image].name,
      title: teamImages[image].title,
      Image: (
        <Img
          className="About-personImage"
          alt={teamImages[image].name}
          sizes={getImage(images, image)}
          style={{ position: 'absolute' }}
        />
      ),
    }))

    const processCircleContent = (
      <div className="About-descriptions">
        <div
          className={classNames('About-element', { 'About-element--active': highlightedProcess === null })}
        >
          <div className="About-descriptionTitle About-descriptionTitle--main">
            {lang.processTitle}
          </div>
        </div>
        {processDescriptions.map((label, idx) => (
          <div
            key={label.title}
            className={classNames('About-element', { 'About-element--active': highlightedProcess === idx })}
          >
            <div className="About-descriptionTitle">{label.title}</div>
            {label.shortDescription && (
              <div className="About-shortDescription">
                {label.shortDescription}
              </div>
            )}
            <div className="About-description">{label.description}</div>
          </div>
        ))}
      </div>
    )
    return (
      <Fragment>
        <SEO
          lang={lang}
          titleOverride={`Bliss DDM | ${lang.menu.weAre}`}
          descriptionOverride={lang.aboutSeo}
          imageOverride="/seo/about.png"
          urlOverride={`https://blissddm.xyz/${lang.lang}/about`}
        />
        <div className="About">
          <div className="About-hero">
            <div className="About-heroContent">
              <div className="About-heroTitle">
                <BlissLabel label={lang.companyLabel} />
                <br />
                {lang.aboutTitle}
              </div>
              <div className="About-heroDescription">
                {lang.aboutDescription}
                <br />
                <br />
                <span className="About-pink">{lang.aboutDescription2}</span>
              </div>
            </div>
            <AnchorLink className="About-arrow" href="#values">
              <Arrow />
            </AnchorLink>
          </div>
          <div className="About-values" id="values">
            <Animate className="BlissLabel--onSection BlissLabel">
              {animationClassNames => (
                <BlissLabel label={lang.menu.weAre} className={animationClassNames} />
              )}
            </Animate>
            <Animate className="About-valuesTitle">
              {animationClassNames => (
                <h1
                  className={animationClassNames}
                  dangerouslySetInnerHTML={{ __html: lang.valuesTitle }}
                />
              )}
            </Animate>
            <div className="About-valuesContainer">
              {values.map((value, idx) => (
                <Animate className={`About-value--${idx} About-value`} key={value.title}>
                  {animationClassNames => (
                    <div
                      className={animationClassNames}
                    >
                      <div className="About-valueNumber">{idx + 1}</div>
                      <h2 className="About-valueTitle">{value.title}</h2>
                      <p className="About-valueDescription">{value.description}</p>
                    </div>
                  )}
                </Animate>
              ))}
            </div>
          </div>
          <div className="About-valuesFooter">
            <Animate className="About-valuesDescriptionTitle">
              {animationClassNames => (
                <p className={animationClassNames}>
                  {lang.valuesDescription}
                </p>
              )}
            </Animate>
            <Animate className="About-valuesDescription">
              {animationClassNames => (
                <p className={animationClassNames} dangerouslySetInnerHTML={{ __html: lang.valuesDescription2 }} />
              )}
            </Animate>
            <Link to={`/${lang.lang}/portfolio`}>
              <Animate className="About-valuesBtn">
                {animationClassNames => (
                  <div className={animationClassNames}>
                    {lang.look} <b>{lang.here}</b> {lang.ourResults}
                  </div>
                )}
              </Animate>
            </Link>
          </div>
          <div className="About-process About-process--mobile">
            <MobileProcessCircle setPage={enable} ref={this.circleContainer}>
              {processCircleContent}
              <div className="About-numbers">
                <div
                  className={classNames('About-number About-number--start', {
                    'About-number--active':
                      highlightedProcess === null,
                  })}
                >
                  <div
                    className="About-numberInner"
                    onClick={() => scrollMobileCircle(0)}
                    onKeyPress={() => scrollMobileCircle(0)}
                    role="button"
                    tabIndex="0"
                  />
                </div>
                {lang.processLabels.map((label, idx) => (
                  <div
                    key={label}
                    className={classNames(`About-number About-number--${idx}`, {
                      'About-number--active':
                        highlightedProcess === idx,
                    })}
                    onClick={() => scrollMobileCircle(idx + 1)}
                    onKeyPress={() => scrollMobileCircle(idx + 1)}
                    role="button"
                    tabIndex="0"
                  >
                    {idx + 1}
                  </div>
                ))}
              </div>
            </MobileProcessCircle>
          </div>
          <div className="About-process About-process--desktop">
            <div className="About-processCircle">
              <ProcessCircle className="About-processCircleIcon" />
              {processCircleContent}
              <div
                className={classNames('About-labels', {
                  'About-labels--highlighted':
                    highlightedProcess !== null,
                })}
              >
                {lang.processLabels.map((label, idx) => (
                  <div
                    key={label}
                    className={classNames(`About-label About-label--${idx}`, {
                      'About-label--active':
                        highlightedProcess === idx,
                    })}
                    onMouseLeave={() => enable(null)}
                    onMouseEnter={() => enable(idx)}
                  >
                    {label}
                  </div>
                ))}
              </div>
              <div className="About-numbers">
                {lang.processLabels.map((label, idx) => (
                  <div
                    key={label}
                    className={classNames(`About-number About-number--${idx}`, {
                      'About-number--active':
                        highlightedProcess === idx,
                    })}
                    onMouseLeave={() => enable(null)}
                    onMouseEnter={() => enable(idx)}
                  >
                    {idx + 1}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="About-team">
            <Animate className="BlissLabel BlissLabel--onSection">
              {animationClassNames => (
                <BlissLabel label={lang.menu.weAre} className={animationClassNames} />
              )}
            </Animate>
            <Animate className="About-teamTitle">
              {animationClassNames => (
                <h1 className={animationClassNames} dangerouslySetInnerHTML={{ __html: lang.teamTitle }} />
              )}
            </Animate>
            <div className="About-persons">
              {team.map(person => (
                <Animate className="About-person" key={person.name}>
                  {animationClassNames => (
                    <div className={animationClassNames}>
                      {person.Image}
                      <h3 className="About-personName">{person.name}</h3>
                      <p className="About-personTitle">{person.title}</p>
                    </div>
                  )}
                </Animate>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default About
